import FeatureGates from '@atlaskit/feature-gate-js-client';
import { UNSAFE_initIfNeeded } from '@atlassian/jira-feature-gates-unsafe-init';
import { addFeatureFlagAccessed } from '@atlassian/react-ufo/feature-flags-accessed';

type WindowFlagVars = {
	isReorderEarlyScriptsEnabled: boolean | undefined;
	allowStatsigInitialisation_DO_NOT_USE: boolean | undefined;
};

const featureGate: typeof FeatureGates.checkGate = (gateName) => {
	// Handle vendorless scripts use of gates
	UNSAFE_initIfNeeded();

	const value = FeatureGates.checkGate(gateName);
	addFeatureFlagAccessed(gateName, value);

	return value;
};

export const fg: typeof FeatureGates.checkGate = (gateName) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined'
		? window
		: globalThis) as unknown as WindowFlagVars;

	if (globalVar.isReorderEarlyScriptsEnabled) {
		if (globalVar.allowStatsigInitialisation_DO_NOT_USE) {
			return featureGate(gateName);
		}

		// StatSig is not allowed to initialise yet, returning false
		return false;
	}

	return featureGate(gateName);
};
