import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options';
import { getBrowser } from '@atlassian/jira-common-util-browser';
import { fg } from '@atlassian/jira-feature-gating';
import { VALIDATION_ERROR_NAME } from '@atlassian/jira-fetch/src/utils/errors';
import throttleLogs from './throttleLogs';

const MAX_CONCURRENCY_FOR_THROTTLE = 5;

const throttleInstance = throttleLogs(MAX_CONCURRENCY_FOR_THROTTLE);

if (typeof window !== 'undefined') {
	window.addEventListener('beforeunload', throttleInstance.flush);
}

type Level = 'info' | 'warn' | 'error';

export type ErrorReason = {
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	readonly [key: string]: string | number | boolean | undefined | void | null;
};

// Note: nested objects are *not* accepted by the backend.
export type Event = Error | ErrorReason;

// Each log call passes in a location - a path to the file with '.' separation. This utility will
// prepend 'jira.frontend.' to this location before it is sent.
// For example, 'jira.frontend.issue.state.reducers.entities.reducer'.
//
// Log messages and events must only contain privacy-safe information.

const isProduction = (): boolean => process.env.NODE_ENV === 'production';

const sendToServer = (body: string, endpoint: string) => {
	const fetchOptions = {
		...defaultOptions,
		method: 'POST',
		body,
	};

	return fetch(endpoint, fetchOptions);
};

const sendToConsole = (
	body: string,
	endpoint: string,
	level: Level,
	message: string,
	eventType: string,
	event: unknown,
) => {
	// eslint-disable-next-line no-console
	console[level](
		'%cJIRA %s %c(POST %s)\n%cMessage\n%c%s\n\n%cEvent body\n%c%s\n\n%cException object and Stack trace (expand to jump to source)\n',
		'font-size: 14px; font-weight: bold;',
		level.toUpperCase(),
		'font-size: 10px; font-family: monospace;',
		endpoint,
		'font-family: sans-serif; font-weight: bold; font-size: 12px',
		'font-family: sans-serif; font-weight: initial;',
		message,
		'font-family: sans-serif; font-weight: bold;',
		'font-family: monospace; font-weight: initial;',
		body,
		'font-family: sans-serif; font-size: 12px; font-weight: bold;',
		event,
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const currentWindow: any = window;
	if (!isProduction() && currentWindow.Cypress) {
		if (!window.cypressSafeErrorsLog) {
			window.cypressSafeErrorsLog = [];
		}

		window.cypressSafeErrorsLog.push({
			body,
			endpoint,
			level,
			message,
		});
	}

	if (eventType !== 'object') {
		const errorMessage = `Invalid log API usage: expected event of type 'object', but was ${eventType}`;
		// eslint-disable-next-line no-console
		console.error(errorMessage);
	}

	if (typeof body !== 'string') {
		const errorMessage = `Invalid log API usage: expected event of type 'string', but was ${eventType}; message will be replaced by: ${String(
			message,
		)}`;
		// eslint-disable-next-line no-console
		console.error(errorMessage);
	}

	return Promise.resolve();
};

const joinArrayFields = (error: Error): Record<string, string | number> =>
	Object.fromEntries(
		Object.entries(error).map(([key, value]) => {
			if (Array.isArray(value)) return [key, value.join(', ')];

			return [key, value];
		}),
	);

const transformError = (error: Error): Record<string, string | number> => {
	const commonProperties = { message: error.message, stack: error.stack };

	// Not importing ValidationError and performing instanceof check because it's causing bundle size increase for skeletons.

	// @ts-expect-error - TS2339 - Property 'errors' does not exist on type 'Error'. | TS2339 - Property 'errors' does not exist on type 'Error'.
	if (error.name === VALIDATION_ERROR_NAME && error.errors && error.errors.map) {
		// @ts-expect-error - TS2339 - Property 'errors' does not exist on type 'Error'.
		const { errors, ...otherProperties } = error;
		// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
		const invalidFields = errors.map((item) => item.field).join(', ');

		// @ts-expect-error - TS2322 - Type '{ name: string; message: string; stack: string | undefined; invalidFields: any; }' is not assignable to type '{ [key: string]: string | number; }'.
		return { ...commonProperties, invalidFields, ...otherProperties };
	}

	const result = { ...commonProperties, ...error };

	return joinArrayFields(result);
};

const transformData = (location: string, message: string, event?: Event) => {
	const { name: browserName, version: browserVersion } = getBrowser();
	return {
		sentFrom: `jira.frontend.${location}`,
		message: String(message),
		event: event instanceof Error ? transformError(event) : event,
		buildKey: window.BUILD_KEY,
		logTime: new Date().toISOString(),
		browserName,
		browserVersion,
	};
};

const sendDataWithThrottle = (
	level: Level,
	location: string,
	message: string,
	event: Event = {},
) => {
	const data = transformData(location, message, event);
	const body = JSON.stringify(data);
	const endpoint = `/rest/internal/2/log/safe/${level}`;

	if (isProduction()) {
		return new Promise<void>((resolve, reject) => {
			throttleInstance.add(() =>
				sendToServer(body, endpoint)
					.then(() => resolve())
					.catch(reject),
			);
		});
	}
	return sendToConsole(body, endpoint, level, message, typeof event, event);
};

const sendDataWithoutThrottle = (
	level: Level,
	location: string,
	message: string,
	event: Event = {},
) => {
	const data = transformData(location, message, event);
	const body = JSON.stringify(data);
	const endpoint = `/rest/internal/2/log/safe/${level}`;

	if (isProduction()) {
		return sendToServer(body, endpoint);
	}
	return sendToConsole(body, endpoint, level, message, typeof event, event);
};

const sendData = (level: Level, location: string, message: string, event: Event = {}) => {
	return fg('jfp-magma-throttle-logging')
		? sendDataWithThrottle(level, location, message, event)
		: sendDataWithoutThrottle(level, location, message, event);
};

/** This function logs privacy safe info.
 * The content must not include any personally identifiable information (PII) or user generated content (UGC).
 * In Jira, UGC includes issue keys, project keys etc. Please see go/jira-ugc for more information.
 *
 * @param location the lower-case, '.' separated path to the file that you are logging from.
 * @param message the message to be logged.
 * @param event
 *
 * @example Usage
 * safeInfoWithoutCustomerData('my.code.location', 'Something went wrong');
 * @example Splunk query
 * `micros_jira-prod_all` LoggingResource INFO "my.location.string"
 */
const safeInfoWithoutCustomerData = async (location: string, message: string, event?: Event) => {
	await sendData('info', location, message, event);
};

/** This function logs privacy safe warnings.
 * The content must not include any personally identifiable information (PII) or user generated content (UGC).
 * In Jira, UGC includes issue keys, project keys etc. Please see go/jira-ugc for more information.
 *
 * @param location the lower-case, '.' separated path to the file that you are logging from.
 * @param message the message to be logged.
 * @param event the error that is thrown.
 *
 * @example Usage
 * safeWarnWithoutCustomerData('my.code.location', 'Something went wrong');
 * @example Splunk query
 * `micros_jira-prod_all` LoggingResource WARN "my.location.string"
 */
const safeWarnWithoutCustomerData = async (location: string, message: string, event?: Event) => {
	await sendData('warn', location, message, event);
};

/** This function logs privacy safe errors.
 * The content must not include any personally identifiable information (PII) or user generated content (UGC).
 * In Jira, UGC includes issue keys, project keys etc. Please see go/jira-ugc for more information.
 *
 * @param location the lower-case, '.' separated path to the file that you are logging from.
 * @param message the message to be logged.
 * @param event the error that is thrown.
 *
 * @example Usage
 * safeErrorWithoutCustomerData('my.code.location', 'Something went wrong');
 * @example Splunk query
 * `micros_jira-prod_all` LoggingResource ERROR "my.location.string"
 */
const safeErrorWithoutCustomerData = async (location: string, message: string, event?: Event) => {
	await sendData('error', location, message, event);
};

// This is a stop gap measure until Sentry is fully rolled out
/** This function logs privacy Unsafe warnings.
 *
 * @param location the lower-case, '.' separated path to the file that you are logging from.
 * @param message the message to be logged.
 * @param event the error that is thrown.
 */
const unsafeErrorWithCustomerData = (location: string, message: string, event: Event = {}) => {
	const data = transformData(location, message, event);
	const body = JSON.stringify(data);
	const endpoint = '/rest/internal/2/log/unsafe/frontend-exception';

	if (isProduction()) {
		if (fg('jfp-magma-throttle-logging')) {
			return new Promise<void>((resolve, reject) => {
				throttleInstance.add(() =>
					sendToServer(body, endpoint)
						.then(() => resolve())
						.catch(reject),
				);
			});
		}
		return sendToServer(body, endpoint);
	}
	return sendToConsole(body, endpoint, 'error', message, typeof event, event);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	safeInfoWithoutCustomerData,
	safeWarnWithoutCustomerData,
	safeErrorWithoutCustomerData,
	unsafeErrorWithCustomerData,
};
