/* eslint-disable @typescript-eslint/no-require-imports */
// Import polyfills first as a safety-net for the remaining imports
import '@atlassian/jira-vendor-polyfills';
import 'react-dom';
import 'react-intl-next';
import '@formatjs/intl-utils';
import '@atlassian/react-sweet-state';
import 'react-loosely-lazy';
import 'react-popper';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import 'styled-components';
import 'history';
import 'path-to-regexp';
import '@atlaskit/analytics-next';
import '@atlaskit/button';
import '@atlaskit/theme';
import '@atlaskit/spinner';
import '@atlaskit/empty-state';
import '@atlassian/react-resource-router';
import '@atlaskit/analytics-namespaced-context';
import '@atlassiansox/analytics-web-client';

// This is needed to make emotion@11 work with RLL,
// as it has a side-effect that moves styles around
// and we need to trigger that before RLL init collects them
// eslint-disable-next-line jira/restricted/emotion, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import '@emotion/react';
// This is needed to make emotion@10 work with RLL,
// as it has a side-effect that moves styles around
// and we need to trigger that before RLL init collects them
// eslint-disable-next-line jira/restricted/emotion, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import '@emotion/core';

import '@atlassiansox/engagekit-ts/engagement-provider';

/**
 * The reason why we import the following module below is that before we make
 * vendor.js a pure library, it imports the following module via both import
 * methods:
 *   + `import '@atlassian/jira-product-analytics-web-client'`
 *   + `import(webpackChunkName: async-jira-feature-flag-client '@atlassian/jira-product-analytics-web-client')`
 * At the same time, entry file `entry/skeleton-navigation.js` uses the async import `async-jira-feature-flag-client`, so
 * that webpack creates a separate chunk internally. When it comes to decide if chunk A should be included into chunk B.
 * Webpack simply uses checks below:
 *   1. if chunkB's module count > chunkA's module count
 *   2. if chunkB's includes chunkA
 * So, when we put the AppBase and some custom code in `commoms.js` while some of the dependencies already defined in `vendor.js`,
 * Webpack checks `moduleCountOf(commons.js) < moduleCountOf(async-jira-feature-flag-client)`, which is true as result. Therefore,
 * `commons.js` never pack the extra `async-jira-feature-flag-client` into the `commons.js`, so that the module got lost in the chunk
 * mappings and ids.
 *
 * In order to fix that issue, I put the line below into vendor, although it seems a bit odd, but it make sure that `async-jira-feature-flag-client`
 * will defined in the webpack module cache.
 */
import '@atlassian/jira-product-analytics-web-client';

/**
 * We need to manually import the locale data for react-intl provider to work
 * the react intl does not import locale-data as it should, possibly because we use an old version of react-intl, which works with an older version of react
 * more info: https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#use-react-163-and-upwards
 *
 * In order to fix that issue, the below line explicitly import and initilise locale data.
 */

// add js-eval-stop API to window object
import './js-eval-stop';

// Need to disable this because this affects how files are bundled together
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const lazyImports = () => {
	require('@atlaskit/analytics-listeners');
	require('@atlaskit/flag');
	require('@atlaskit/popper');
	require('date-fns');
	require('date-fns-tz');
	require('@atlassian/jira-react-redux');
	require('react-relay');
	require('redux-observable');
	require('redux');
	require('relay-runtime');
	require('react-transition-group');

	// rx-js
	require('rxjs/BehaviorSubject');
	require('rxjs/Observable');
	require('rxjs/ReplaySubject');
	require('rxjs/Scheduler');
	require('rxjs/Subject');
	require('rxjs/Subscription');
	require('rxjs/add/observable/concat');
	require('rxjs/add/observable/defer');
	require('rxjs/add/observable/empty');
	require('rxjs/add/observable/forkJoin');
	require('rxjs/add/observable/from');
	require('rxjs/add/observable/fromEvent');
	require('rxjs/add/observable/fromPromise');
	require('rxjs/add/observable/interval');
	require('rxjs/add/observable/merge');
	require('rxjs/add/observable/never');
	require('rxjs/add/observable/of');
	require('rxjs/add/observable/throw');
	require('rxjs/add/observable/timer');
	require('rxjs/add/observable/zip');
	require('rxjs/add/operator/bufferCount');
	require('rxjs/add/operator/bufferTime');
	require('rxjs/add/operator/bufferToggle');
	require('rxjs/add/operator/bufferWhen');
	require('rxjs/add/operator/catch');
	require('rxjs/add/operator/concat');
	require('rxjs/add/operator/concatMap');
	require('rxjs/add/operator/debounce');
	require('rxjs/add/operator/debounceTime');
	require('rxjs/add/operator/delay');
	require('rxjs/add/operator/distinctUntilChanged');
	require('rxjs/add/operator/do');
	require('rxjs/add/operator/exhaustMap');
	require('rxjs/add/operator/filter');
	require('rxjs/add/operator/finally');
	require('rxjs/add/operator/first');
	require('rxjs/add/operator/groupBy');
	require('rxjs/add/operator/ignoreElements');
	require('rxjs/add/operator/last');
	require('rxjs/add/operator/map');
	require('rxjs/add/operator/mapTo');
	require('rxjs/add/operator/merge');
	require('rxjs/add/operator/mergeAll');
	require('rxjs/add/operator/mergeMap');
	require('rxjs/add/operator/pairwise');
	require('rxjs/add/operator/pluck');
	require('rxjs/add/operator/reduce');
	require('rxjs/add/operator/retry');
	require('rxjs/add/operator/retryWhen');
	require('rxjs/add/operator/sample');
	require('rxjs/add/operator/scan');
	require('rxjs/add/operator/share');
	require('rxjs/add/operator/startWith');
	require('rxjs/add/operator/switchMap');
	require('rxjs/add/operator/switchMapTo');
	require('rxjs/add/operator/take');
	require('rxjs/add/operator/takeLast');
	require('rxjs/add/operator/takeUntil');
	require('rxjs/add/operator/takeWhile');
	require('rxjs/add/operator/throttleTime');
	require('rxjs/add/operator/toArray');
	require('rxjs/add/operator/window');
	require('rxjs/add/operator/withLatestFrom');
	require('rxjs/add/operator/toPromise');
	require('rxjs/add/operator/zip');
	require('rxjs/observable/ArrayObservable');
	require('rxjs/observable/ConnectableObservable');
	require('rxjs/observable/DeferObservable');
	require('rxjs/observable/ErrorObservable');
	require('rxjs/observable/ForkJoinObservable');
	require('rxjs/observable/FromEventObservable');
	require('rxjs/observable/IntervalObservable');
	require('rxjs/observable/NeverObservable');
	require('rxjs/observable/RangeObservable');
	require('rxjs/observable/ScalarObservable');
	require('rxjs/observable/combineLatest');
	require('rxjs/observable/defer');
	require('rxjs/observable/dom/AjaxObservable');
	require('rxjs/observable/dom/ajax');
	require('rxjs/observable/forkJoin');
	require('rxjs/observable/fromPromise');
	require('rxjs/observable/interval');
	require('rxjs/observable/never');
	require('rxjs/observable/of');
	require('rxjs/observable/range');
	require('rxjs/operators/buffer');
	require('rxjs/operators/bufferCount');
	require('rxjs/operators/bufferWhen');
	require('rxjs/operators/combineLatest');
	require('rxjs/operators/concatMap');
	require('rxjs/operators/exhaustMap');
	require('rxjs/operators/finalize');
	require('rxjs/operators/first');
	require('rxjs/operators/merge');
	require('rxjs/operators/multicast');
	require('rxjs/operators/refCount');
	require('rxjs/operators/sample');
	require('rxjs/operators/share');
	require('rxjs/operators/switchMapTo');
	require('rxjs/operators/takeWhile');
	require('rxjs/operators/tap');
	require('rxjs/operators/toArray');
	require('rxjs/scheduler/QueueAction');
	require('rxjs/scheduler/QueueScheduler');
	require('rxjs/scheduler/VirtualTimeScheduler');
	require('rxjs/scheduler/queue');
};
